import React from "react"
import "./contact.css"
import "./contact-styles.scss"

export default function Contact() {
  return (
    <div className="contact-home">
      <div className="contact-title-container">
        <div className="contact-title">
          We would love to hear from you
        </div>
      </div>
      <div className="contact-email-container">
        <div className="contact-email">
          Email us at
          <br />
          <a href="mailto:info@beytna.foundation" className="email-roller">
                <div className="rolling-text">info@beytna.foundation</div>
          </a>
        </div>
      </div>
    </div>
  )
}
